<template>
  <div></div>
</template>

<script>
import { Loading, Message } from 'element-ui'
import { authLogin, loginUserInfo } from '@/api/user'

export default {
  name: 'AuthLogin',
  data() {
    return {
      loading: false
    }
  },
  methods: {
    doAuthLogin() {
      let that = this

      const loadingInstance = Loading.service({ fullscreen: true })

      const { origin, auth } = this.$route.query

      if (origin && auth) {
        authLogin(origin, auth).then(() => {
          loginUserInfo().then(resp => {
            if (resp) {
              window.localStorage.setItem('roles', resp.roles)
              sessionStorage.setItem('userInfo', JSON.stringify(resp))
              that.$store.commit('user/SET_INFO')

              window.localStorage.setItem('tz_origin', window.document.referrer)
              loadingInstance.close()

              if (resp.userRoles && Array.isArray(resp.userRoles)) {
                if (
                  resp.userRoles.some(item => {
                    return item.system == 'ProbeSystem' && item.role == 1
                  })
                ) {
                  this.$router.push('/')
                } else {
                  document.location.href =
                    window.document.referrer ||
                    'https://auth.wummi.cn:8335/index.html'
                }
              }
            }

            //
          })
        })
      } else {
        loadingInstance.close()
        Message.error('第三方登录参数缺失')
      }
    }
  },
  mounted() {
    this.doAuthLogin()
  }
}
</script>
